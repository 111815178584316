import React from 'react';
import { cn } from 'src/utils/cn';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  label?: React.ReactNode;
  action?: React.ReactNode;
  labelClassName?: string;
};

export function Divider({
  action,
  label,
  className,
  labelClassName,
  ...props
}: Props) {
  return (
    <div
      className={cn(
        'relative my-3 w-full border-b border-secondary text-center',
        className
      )}
      data-testid="divider"
      {...props}
    >
      {(action || label) && (
        <div
          className={cn(
            'absolute left-1/2 top-1/2 flex h-4 -translate-x-1/2 -translate-y-1/2 items-center justify-center gap-2 bg-background px-3 font-melodrama uppercase tracking-widest',
            labelClassName
          )}
          data-testid="divider-label-wrapper"
        >
          {label && <div>{label}</div>}
          {action && <div>{action}</div>}
        </div>
      )}
    </div>
  );
}
