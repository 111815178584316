'use client';

import Link from 'next/link';
import { UserIcon } from 'lucide-react';
import { useIsMobile } from 'src/app/(providers)/DeviceContext';
import { Button } from 'src/components/Button/Button';
import { useValidatedParams } from 'src/hooks/useParsedParams';
import { Translated } from 'src/i18n/TranslationsProvider';
import { z } from 'zod';

const paramsSchema = z.object({
  mapSlug: z.string().optional(),
});

export function NavbarLoginButton() {
  const isMobile = useIsMobile();
  const isMapView = useValidatedParams(paramsSchema).mapSlug !== undefined;

  return (
    <Link href="/login" passHref className="flex-shrink-0">
      <Button
        variant={isMobile && isMapView ? 'secondary' : 'transparent'}
        rounded={isMobile}
        square={isMobile}
      >
        {isMobile && isMapView ? (
          <UserIcon size={isMobile ? 32 : 24} />
        ) : (
          <Translated id="generic.sign-in" />
        )}
      </Button>
    </Link>
  );
}
