'use client';

import React from 'react';
import Link from 'next/link';
import { useModalActions } from 'src/store/modalsStore/modalsStore';

type Props = React.ComponentProps<typeof Link>;

export function NavbarUserMenuItem({ onClick, ...props }: Props) {
  const { toggle: setOpen } = useModalActions('user-menu');

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setOpen(false);
    onClick?.(event);
  };

  return <Link {...props} onClick={handleClick} />;
}
